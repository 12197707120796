<template>
  <section>
    <div class="richtext">
      <p>
        Sulfuric acid is a kind of active binary inorganic strong acid, which
        can react with most metals. High-concentration sulfuric acid has strong
        water absorption and can be used as a dehydrating agent, carbonized
        wood, paper, cotton and linen fabrics and biological skins and other
        carbohydrate-containing substances. When mixed with water, it also
        releases a lot of heat energy. With high corrosivity and oxidizability,
        it should be used with caution. As is an important industrial raw
        material, it can be used to make fertilizers, drugs, explosives,
        pigments, detergents, batteries and so on. It is also widely used in
        industries such as oil purification, metal smelting, and dyes. Often
        used as a chemical reagent, it can be used as a dehydrating agent and a
        sulfonating agent in organic synthesis.
      </p>
      <p><br /></p>
      <h2><strong>Models Of Sulfuric Acid Metering Pump</strong></h2>
      <p><br /></p>
      <p>
        NEWDOSE sulfuric acid metering pump has a lots of models aiming at
        different on-site working conditions, different water medium, different
        medium temperature, different installation process and different water
        treatment capacity. You can choose from the following:
      </p>
      <p><br /></p>
      <h2>
        <strong>Installation Method Of Sulfuric Acid Metering Pump</strong>
      </h2>
      <p><br /></p>
      <p>
        1. When installing the sulfuric acid metering pump, you should choose a
        solid level ground and keep it stable. The inlet and outlet of the pump
        should be installed with a regular surface for maintenance.
      </p>
      <p><br /></p>
      <p>
        2. Try to avoid installing the machine outdoors. If it must be installed
        outdoors, it should be covered with a protective cover. If the pump is
        equipped with an electronic controller, it must be protected.
      </p>
      <p><br /></p>
      <p>
        3. The pump made of PVC material should avoid direct sunlight exposure
        to prevent material embrittlement.
      </p>
      <p><br /></p>
      <p>
        4. Before piping, the chemical liquid used, temperature conditions and
        delivery should be considered with different pipe materials to meet
        actual requirements. For example, if the temperature is above 60℃, PP
        pipe fittings should be installed.
      </p>
      <p><br /></p>
      <p>
        5. When piping, be careful not to leave any impurities or debris in the
        pipe and clean the pipe with clean water if necessary.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Sulfuric Acid Metering Pump",
}
</script>